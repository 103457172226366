import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {bg} from "../assets";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      crestowave {new Date().getFullYear()}{'.'} <br/>
      The Services are virtual social entertainment games, and do not involve any real money bets or prizes. 18+.
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',
          borderTop: '0.1px solid #ffffff',
        }} style={{
        }}
      >
        <Container maxWidth="sm">
        <Typography variant="body1">info@crestowave.com</Typography>
        <Typography variant="body1">Level 37, 2/26 Park St, Sydney NSW 2000, Australia</Typography>
        <Typography variant="body1">+61 1800 571 894</Typography>
        <br/>
        <hr style={{border: "0.1px solid #ffffff"}}/>
        <br/>

          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy{' '}
            </Link>
            |{' '}
            <Link to="/contacts"  style={{color: "#ffffff", textDecoration: "none"}}>
              Contacts
            </Link>
          </Typography>
          <Copyright />
        </Container>
      </Box>
  );
}
