import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent'; // Import CardContent
import Button from '@mui/material/Button'; // Import Button
import Container from '@mui/material/Container';
import games from '../data/games.js';
import Typography from '@mui/material/Typography';

function GameList() {
  return (
    <Container maxWidth="lg">
     <Typography textAlign="center" gutterBottom variant="h4" fontWeight="900">Our selection of games</Typography>

      <Grid container spacing={3}>
        {games.map((game) => (
          <Grid item xs={12} sm={4} md={3} key={game.id}>
            <Card
              sx={{
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                }, backgroundColor: "#3a2035", color: "#ffffff"
              }}
            >
              <CardActionArea component={Link} to={`/game/${game.id}`}>
                <CardMedia
                  component="img"
                  alt={game.title}
                  height="180"
                  image={game.image}
                  title={game.title} 
                  style={{ fontSize: '14px' }}
                />
                <CardContent  textAlign="center">
                  <Typography variant="h6" component="div" gutterBottom>
                    {game.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link} mx="auto"
                    to={`/game/${game.id}`}
                  >
                    Play now
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GameList;
