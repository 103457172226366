import React from 'react';
import Container from "@mui/material/Container";
import Header from './Header';
import Typography from '@mui/material/Typography';

export default function About() {
  return (
    <div className="about-page">
    <Header/>
    <Container>
    <Typography variant="h3" textAlign="center" gutterBottom>
    Explore crestowave.com
    </Typography>

    <Typography variant="body1" paragraph>
        Welcome to the domain of crestowave, a sanctuary for gamers of all skill levels seeking thrilling escapades. Whether you're a seasoned pro or a casual enthusiast looking for leisurely fun, our platform offers a unique experience tailored for you.
    </Typography>

    <Typography variant="body1" paragraph>
        At crestowave, we take immense pride in our extensive array of complimentary slot games, designed to suit every preference. Our selection ranges from timeless fruit slot machines to advanced video slots, all developed by industry leaders to ensure captivating graphics and seamless gameplay.
    </Typography>

    <Typography variant="body1" paragraph>
        What sets crestowave apart is our commitment to offer all our games at no cost. Here, surprises such as hidden fees or extra charges are non-existent – just uninterrupted fun. Our platform is the perfect escape for those looking to unwind, explore new gaming realms, or simply enjoy hassle-free entertainment.
    </Typography>

    <Typography variant="body1" paragraph>
        Beyond our wide range of slot games, crestowave also provides a wealth of information to enhance your gaming journey. Our site is packed with advice, tactics, and expert knowledge designed to elevate your play and increase your pleasure.
    </Typography>

    <Typography variant="body1" paragraph>
        Don't wait any longer! Dive into crestowave today and embark on an exceptional journey through some of the finest free slot games available. With regular updates and limitless opportunities for enjoyment, an unforgettable adventure awaits.
    </Typography>

    </Container>
      </div>
  )
}
