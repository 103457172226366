import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  pallete: {
    primary: {
      dark: '#2a0d33',       // Darker violet for the darkest shade
      main: '#220a29',       // Primary dark violet shade
      main600: '#3b1240',    // Slightly lighter, more vivid violet
      main500: '#532159',    // Medium violet with a touch of warmth
      main400: '#6a3172',    // Warmer, inviting medium-light violet
      main300: '#84518b',    // Lighter violet, moving towards a softer tone
      main200: '#9e71a4',    // Very light violet, soft and pastel-like
      main100: '#b891bd',    // Light pastel violet, very soft
      main50: '#d2b1d6', 
    },
    rgba: {
      main: 'rgba(19, 11, 18, 0.8)',
      main600: 'rgba(39, 22, 36, 0.8)',
      main500: 'rgba(58, 32, 53, 0.8)',
      main400: 'rgba(78, 43, 71, 0.8)',
      main300: 'rgba(97, 54, 89, 0.8)',
      main200: 'rgba(129, 94, 122, 0.8)',
      main100: 'rgba(160, 134, 155, 0.8)',
      main50: 'rgba(192, 175, 189, 0.8)',
    },
    text: {
      primary: '#ffffff',
    },
    secondary: {
      main: '#0bf5ab', 
      secondary: '#9ff0aa'
    },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Madimi One, sans-serif', 
    color: "white",
    body1: {
      fontSize: "18px",
    },
    h1: {
      fontSize: '3rem',
    '@media (min-width:900px)': {
      fontSize: '6rem',
    },
  },
    h3: {
      fontSize: '1.7rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.4rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
