import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import DisclaimerModal from './DisclaimerModal'; 
import {logo} from "../assets";

function Header() {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMenuClick = () => {
    setOpen(!open);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const renderMobileMenu = () => (
    <Drawer anchor="left" open={open} onClose={handleMenuClick}>
      <List>
        <ListItem button onClick={handleOpenModal}>
          <ListItemText primary="Disclaimer" />
        </ListItem>
        <ListItem button component={Link} to="/about">
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button  component={Link} to="/contacts">
          <ListItemText primary="Contacts" />
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <div>
      <AppBar position="static" style={{ padding: 12, background: "transparent" }}>
        <Toolbar >
          <Box className="logo" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'white'}}>
             <Typography variant="h4" fontWeight="900">crestowave</Typography>
            </Link>
            <img src={logo} alt="" width="40px" /> 
          </Box>
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div>
              <Button color="inherit" onClick={handleOpenModal}>
                Disclaimer
              </Button>
              <Button color="inherit" component={Link} to="/about">
                About Us
              </Button>
              <Button color="inherit"  component={Link} to="/contacts">
              Contacts
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && renderMobileMenu()}
      
      {/* Render the DisclaimerModal */}
      <DisclaimerModal open={isModalOpen} handleClose={handleCloseModal} />
    </div>
  );
}

export default Header;
