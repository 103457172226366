import { Hero, GamesList, CTA, Benefits, Contacts, CookieModal, AgeDisc } from "./components";
import { ThemeProvider } from '@mui/material';
import theme from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <Hero/>
      <GamesList/>
      <CTA/>
      <AgeDisc/>
      <CookieModal/>
      </ThemeProvider>
    </div>
  );
}

export default App;
