// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { bg } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="70vh"
      display="flex"
      flexDirection="column"
      style={{
        background: `linear-gradient(20deg, rgba(0,0,0,.8) 20%, rgba(0, 0, 0,.9) 80%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Container maxWidth="xl">
          <Box p={4} display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="center" maxWidth="60%" textAlign="center" mx="auto" sx={{background:"rgba(19, 11, 18, 0.3)", borderRadius: "2rem"}}>
            <Typography variant="h1" gutterBottom style={{ fontWeight: "900" }}>
              Play games online
            </Typography>
            
          </Box>
      </Container>
    </Box>
  );
}

export default Hero;
