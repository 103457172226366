// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { bg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div
      style={{
        background: `linear-gradient(20deg, rgba(0,0,0,.8) 20%, rgba(0, 0, 0,.9) 80%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={{ xs: "column", md: "row" }}
          py={4}
          gap="4rem"
        >
          {/* Text Block */}
          <Grow in={true} timeout={1200} appear={true}>
            <Box mt={3}>
              <Typography variant="h4" gutterBottom>
                Explore and Enjoy Our Diverse Social Games Collection!
              </Typography>
              <Typography variant="body1" paragraph>
                We offer a safe and inviting environment for players of every
                stripe to immerse themselves in our extensive game library.
                Whether you're a seasoned enthusiast or stepping into the world
                of gaming for the first time, our platform is tailored to suit
                gamers of all preferences. Our collection spans from
                time-honored favorites to cutting-edge video slots, all adorned
                with eye-catching graphics and immersive gameplay that promises
                to delight and engage you.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/about"
              >
                About us
              </Button>
            </Box>
          </Grow>
        </Box>
      </Container>
    </div>
  );
}

export default CTA;
